import useAuth from '../hooks/useAuth';

const AuthHeader = () => {
    const { user, token } = useAuth();

    if (user && token) {
        return { Authorization: `Bearer ${token}` };
    }
    return {};
};

export default AuthHeader;